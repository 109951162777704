import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import GetInTouch from "../components/getInTouch/GetInTouch";
import AppFooter from "../components/footer/AppFooter";
import TermAndConditions from "../components/TermAndConditions/TermAndConditions";

const TermAndConditionsPage = () => {
  return (
    <div>
      <AppNavbar wUnset="width-unset" />
      <TermAndConditions />
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default TermAndConditionsPage;
