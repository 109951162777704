import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Home } from "./pages/Home";
import AboutUS from "./pages/AboutUS";
import ContactPage from "./pages/ContactPage";
import WorkWithUsPage from "./pages/WorkWithUsPage";
import ServicePage from "./pages/ServicePage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import { Route, Routes } from "react-router-dom";
import SeoBlogDetail from "./components/blog/BlogDetail/SeoBlogDetail";
import BlogNegativeDemand from "./components/blog/BlogDetail/BlogNegativeDemand";
import BlogDetails from "./components/blog/BlogDetail/BlogDetails";
import AdTech from "./components/blog/BlogDetail/AdTech";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermAndConditionsPage from "./pages/TermAndConditionsPage";
import ScrollToTop from "./ScrollToTop";
import PerformanceMarketingPage from "./pages/PerformanceMarketingPage";
import BrandingPage from "./pages/BrandingPage";
import SeoPage from "./pages/SeoPage";
import ImpactCampaingsPage from "./pages/ImpactCampaingsPage";
import OnlineReputationPage from "./pages/OnlineReputationPage";
import CreativePersonalization from "./pages/CreativePersonalization";
import NtiermarketingPage from "./pages/NtiermarketingPage";
import Influencermarketing from "./pages/Influencermarketing";
import VideoShootsPage from "./pages/VideoShootsPage";
import DigitalStrategy from "./pages/DigitalStrategy";
import WebDevelopmentPage from "./pages/WebDevelopmentPage";

function App() {
  return (
    <div className="App bg_clr_soil overflow-hidden ">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUS />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/work-with-us" element={<WorkWithUsPage />} />
        <Route path="/blog-detail" element={<BlogDetailsPage />} />
        <Route path="/seo-for-bussiness" element={<SeoBlogDetail />} />
        <Route
          path="/negative-marketing-demands"
          element={<BlogNegativeDemand />}
        />
        <Route path="/first-thing-to-develop" element={<BlogDetails />} />
        <Route path="/automation-to-ai" element={<AdTech />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route
          path="/term-and-conditions"
          element={<TermAndConditionsPage />}
        />
        <Route
          path="/performance-marketing"
          element={<PerformanceMarketingPage />}
        />
        <Route path="/branding" element={<BrandingPage />} />
        <Route path="/impact-campaings" element={<ImpactCampaingsPage />} />
        <Route path="/online-reputation" element={<OnlineReputationPage />} />
        <Route path="/seo" element={<SeoPage />} />
        <Route
          path="/creative-personalization"
          element={<CreativePersonalization />}
        />
        <Route path="/n-tier-marketing" element={<NtiermarketingPage />} />
        <Route path="/influencer-marketing" element={<Influencermarketing />} />
        <Route path="/video-shoots" element={<VideoShootsPage />} />
        <Route
          path="/digital-strategy-and-planning"
          element={<DigitalStrategy />}
        />
        <Route path="/website-development" element={<WebDevelopmentPage />} />
      </Routes>
    </div>
  );
}

export default App;
