import React from "react";
import "./AppFooter.css";
import { Col, Row } from "react-bootstrap";
import logo from "../../assets/images/svg/logo.svg";
import mailIcon from "../../assets/images/png/footerMailIcon.png";
import insta from "../../assets/images/svg/footerInstaIcon.svg";
import twitter from "../../assets/images/svg/footerTwitterIcon.svg";
import { Link } from "react-router-dom";

const AppFooter = () => {
  return (
    <div className="pt-5 pb-2">
      <div className="container pt-xl-5">
        <Row className="justify-content-between">
          <Col md={3}>
            <div className="">
              <img
          loading="lazy" className="footer_logo" src={logo} alt="logo" />
              <p className="ff_inter fs_16 text-white mb-0 opacity_60 pe-4 mt-2">
                At Bamboo Ruts, we believe in nurturing and strengthening the
                roots of your business
              </p>
              <div className="d-flex align-items-center mt-3 gap-3">
                <a
                  target="_blank"
                  href="https://www.instagram.com/bamboo.ruts/"
                >
                  <div className="footer-social-icons-bg d-flex align-items-center justify-content-center">
                    <img
          loading="lazy"
                      style={{ width: "18px", height: "18px" }}
                      src={insta}
                      alt="instagram"
                    />
                  </div>
                </a>
                <a href="mailto:mailto:info@bambooruts.com">
                  <div className="footer-social-icons-bg d-flex align-items-center justify-content-center">
                    <img
          loading="lazy"
                      style={{ width: "20px", height: "17px" }}
                      src={mailIcon}
                      alt="mail"
                    />
                  </div>
                </a>
                <a target="_blank" href="https://twitter.com/BambooRuts">
                  <div className="footer-social-icons-bg d-flex align-items-center justify-content-center">
                    <img
          loading="lazy"
                      style={{ width: "18px", height: "18px" }}
                      src={twitter}
                      alt="twitter"
                    />
                  </div>
                </a>
              </div>
            </div>
          </Col>
          <Col className="pt-2" md={8}>
            <div>
              <Row className="mt-4  ">
                <Col xs={6} sm={4}>
                  <div>
                    <ul className="m-0 p-0">
                      <li className="ff_inter fw-medium fs_16 text-white">
                        Services
                      </li>
                      <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                        Website Development
                      </li>
                      <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                        Digital Strategy
                      </li>
                      <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                        App Development
                      </li>
                      <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                        Software Development
                      </li>
                      <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                        Technology Consulting
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={6} sm={4}>
                  <div>
                    <ul className="m-0 p-0">
                      <li className="ff_inter fw-medium fs_16 text-white">
                        Support
                      </li>
                      <Link to={"/contact"}>
                        <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                          Contact us
                        </li>
                      </Link>
                      <Link to={"/work-with-us"}>
                        <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                          Carrier
                        </li>
                      </Link>
                    </ul>
                  </div>
                </Col>
                <Col xs={6} sm={4}>
                  <div>
                    <ul className="m-0 p-0 mt-4">
                      <li className="ff_inter fw-medium fs_16 text-white">
                        Legal
                      </li>
                      <Link to={"/term-and-conditions"}>
                        <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                          Terms & Conditions
                        </li>
                      </Link>
                      <Link to={"/privacy-policy"}>
                        <li className="ff_inter fw-light fs_16 text-white opacity_60 mt-2">
                          Privacy Policy
                        </li>
                      </Link>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="hr-line mt-5"></div>
      <p className="ff_inter fs_16 text-center text-white mb-0 mt-2 opacity_60">
        ©2023 Bamboo ruts. All Rights Reserved.
      </p>
    </div>
  );
};

export default AppFooter;
