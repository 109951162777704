import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import influence from "../assets/images/png/pandaInfluenceMarketing.webp";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const VideoShootsPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            1. Influencer Strategy Development{" "}
          </span>
          Crafting a tailored influencer marketing strategy is crucial. We
          identify the right influencers whose values align with your brand to
          maximize impact.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Audience Research</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Influencer Identification
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Campaign Strategy Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Goal Setting and KPIs
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            2. Influencer Outreach & Management{" "}
          </span>{" "}
          We handle every aspect of influencer collaboration, from initial
          outreach to managing relationships and ensuring content aligns with
          your brand's message.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Negotiation & Contracts
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Relationship Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Briefing & Approval
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            3. Content Creation & Distribution{" "}
          </span>
          Our team collaborates with influencers to create authentic and
          compelling content that resonates with their audience while staying
          true to your brand identity.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Sponsored Posts</li>
          <li className="text-white ff_inter fs_16 mt-1">Product Reviews</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Takeovers
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Storytelling Campaigns
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Storytelling Campaigns </span>
          We monitor campaigns in real-time, optimizing strategies based on
          performance metrics to ensure maximum engagement and ROI.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Engagement Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">A/B Testing</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Improvement
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">5. Compliance & Legal Support </span>
          Navigating the legal and compliance aspects of influencer marketing is
          crucial. We ensure all campaigns adhere to guidelines and regulations.
          Ready to elevate your digital strategy and achieve unparalleled
          growth? Contact Bamboo Ruts Digital Marketing Agency to discover how
          our digital strategy and planning services can unlock your business
          potential.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">C Compliance</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Contractual Agreements
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Disclosure Monitoring
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            6. Performance Tracking & Reporting{" "}
          </span>
          Transparency is key. We provide comprehensive reports outlining
          campaign performance, ROI metrics, and actionable insights for future
          strategies.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">KPI Tracking</li>
          <li className="text-white ff_inter fs_16 mt-1">ROI Measurement</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Reports
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Insights & Recommendations
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Harness the Power of Influencers to Grow Your Brand"
          mainImg={influence}
          mainPara="Influencer marketing has become a cornerstone of digital strategy, and at Bamboo Ruts Digital Marketing Agency, we specialize in leveraging influencers to elevate brands and connect with targeted audiences authentically."
          mainHeading="Influencer Marketing Services at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="pt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Expertise & Experience",
              para: `Our team has extensive experience collaborating with influencers across various industries, ensuring effective campaigns.`,
            },
            {
              head: "Tailored Strategies",
              para: `We customize strategies to fit your brand’s unique goals, ensuring influencer collaborations align with your business objectives.`,
            },
            {
              head: "Data-Driven Insights",
              para: `We leverage data analytics to optimize campaigns, ensuring every decision is based on measurable results.`,
            },
            {
              head: "Creative Excellence",
              para: `We prioritize creativity and innovation in influencer partnerships, crafting memorable campaigns that resonate with audiences.`,
            },
            {
              head: "Client-Centric Approach",
              para: `Your success is our priority. We collaborate closely with you to achieve your marketing goals through influencer partnerships.`,
            },
          ]}
          journeyHead="Elevate Your Brand with Influencer Marketing"
          brandJourney="Ready to harness the influence of key personalities in your industry? Contact Bamboo Ruts Digital Marketing Agency to explore how our influencer marketing services can amplify your brand's reach and engagement."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default VideoShootsPage;
