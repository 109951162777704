import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import webDevelopmentService from "../assets/images/png/webDevelopmentService.jpg";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const WebDevelopmentPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            1. Business Analysis & Goal Setting{" "}
          </span>
          We begin by conducting a thorough analysis of your business objectives
          and market position. This forms the foundation for developing a robust
          digital strategy aligned with your long-term goals.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Business Objectives Assessment
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Competitor Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">SWOT Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Goal Setting and KPI Definition
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            2. Audience Segmentation & Persona Development{" "}
          </span>{" "}
          Understanding your target audience is crucial. We identify key
          demographics, behaviors, and preferences to create detailed buyer
          personas that guide our digital initiatives.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Audience Research</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Persona Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Customer Journey Mapping
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Behavior Analysis</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            3. Customized Digital Strategy Development{" "}
          </span>
          Based on insights gathered, we develop a tailored digital strategy
          that outlines actionable steps to achieve your objectives. This
          strategy encompasses all aspects of digital marketing, ensuring a
          cohesive approach.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Multi-Channel Strategy Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Strategy & Planning
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Campaign Ideation</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Budget Allocation & Resource Planning
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Integrated Campaign Execution </span>
          We execute multi-channel digital campaigns that drive engagement and
          conversions. Our team ensures seamless integration across platforms to
          maximize reach and impact.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            • Search Engine Optimization (SEO)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Pay-Per-Click (PPC) Advertising
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Marketing
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Email Marketing Campaigns
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            5. Performance Monitoring & Optimization{" "}
          </span>
          Continuous monitoring and analysis are key to success. We track
          campaign performance, analyze data, and optimize strategies in
          real-time to achieve optimal results.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking & Reporting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Conversion Rate Optimization (CRO)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            A/B Testing & Experimentation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">ROI Measurement</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            6. Technology Integration & Innovation{" "}
          </span>
          We leverage cutting-edge technologies and tools to enhance campaign
          effectiveness and efficiency. From AI-powered analytics to marketing
          automation, we ensure your strategy remains at the forefront of
          innovation.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Marketing Technology Assessment
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Automation Implementation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Data Integration</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Predictive Analytics
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Agile Strategy Refinement </span>
          Digital landscapes evolve rapidly. We employ an agile approach to
          adapt strategies, capitalize on emerging trends, and maintain
          competitive advantage.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Strategy Reviews
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Trend Monitoring & Adaptation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Scalable Solutions</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Crisis Management Planning
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Elevate Your Online Brand with Professional Web Solutions"
          mainImg={webDevelopmentService}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we understand that your website is more than just an online presence—it's your digital storefront. Our website development services are designed to create stunning, user-friendly websites that captivate visitors and drive conversions. Whether you're launching a new site or revamping an existing one, we're here to help you achieve your online goals."
          mainHeading="Craft Your Digital Presence with Expert Website Development"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="pt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Customized Solutions",
              para: `We tailor our website development approach to your unique business needs and objectives. From design to functionality, every aspect of your website is crafted to reflect your brand identity and enhance user experience.`,
            },
            {
              head: "Responsive Design",
              para: `Ensure your website looks great and functions seamlessly across all devices—desktops, tablets, and smartphones. Responsive design not only improves user experience but also boosts your site's SEO performance.`,
            },
            {
              head: "Scalability and Flexibility",
              para: `Build a website that grows with your business. Our scalable solutions allow for easy integration of new features and functionalities as your business evolves and expands.`,
            },
            {
              head: "Enhanced Security",
              para: `We thrive on creativity and innovation, implementing strategies that stand out and drive meaningful engagement.`,
            },
            {
              head: "Customer-Centric Focus",
              para: `: Your success is our priority. We strive to exceed expectations, fostering long-term partnerships based on trust and results.`,
            },
          ]}
          journeyHead="Transform Your Digital Presence Today"
          brandJourney="Elevate your brand with our expert solutions. Enhance engagement, boost visibility, and drive growth. Transform your digital presence today for a brighter tomorrow."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default WebDevelopmentPage;
