import React, { useState } from "react";
import "./AppNavbar.css";
import logo from "../../assets/images/svg/logo.svg";
import panda from "../../assets/images/svg/pandaHanging.svg";
import { Link } from "react-router-dom";
const AppNavbar = ({
  textWhite,
  wUnset,
  display_home,
  display_services,
  display_blog,
  display_about,
  opacity_100,
  opacity_70,
  opacity_100_work,
  home_bold,
  about_bold,
  blog_bold,
  service_bold,
  contact_bold,
}) => {
  const [nav, setnav] = useState(true);
  if (nav) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }
  return (
    <div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={"/"}>
            <img
          loading="lazy" className="logo position-relative " src={logo} alt="logo" />
          </Link>
          <div className={`navbar-width ${wUnset}`}>
            <ul
              className={`mb-0 p-0 d-flex align-items-center justify-content-md-start gap-lg-5 gap-4 ${
                nav === true ? "nav-hidden" : "nav-open"
              }`}
            >
              <li className="position-relative">
                <Link
                  to={"/"}
                  className={`ff_inter fw-medium clr_light_black opacity_70 ${home_bold} ${opacity_70} ${textWhite}`}
                >
                  Home
                </Link>
                {display_home}
              </li>
              <li className="position-relative">
                <Link
                  to={"/about"}
                  className={`ff_inter fw-medium clr_light_black ${about_bold} opacity_70 ${textWhite}`}
                >
                  About
                </Link>
                {display_about}
              </li>
              <li className="position-relative">
                <Link
                  className={`ff_inter fw-medium clr_light_black opacity_70 ${textWhite} ${service_bold}`}
                  to={"/service"}
                >
                  Services
                </Link>
                {display_services}
              </li>
              <li className="position-relative">
                <Link
                  className={`ff_inter fw-medium clr_light_black opacity_70 ${textWhite} ${blog_bold}`}
                  to={"/blog"}
                >
                  Blog
                </Link>
                {display_blog}
              </li>
              <li className="position-relative">
                <Link
                  className={`ff_inter fw-medium clr_light_black opacity_70 ${opacity_100} ${textWhite}`}
                  to={"/contact"}
                >
                  Contact
                </Link>
              </li>
              <li className="position-relative">
                <Link
                  className={`ff_inter fw-medium clr_light_black opacity_70 ${opacity_100_work} text-nowrap zindex ${textWhite}`}
                  to={"/work-with-us"}
                >
                  Work With Us
                </Link>
              </li>
            </ul>
            <div className="text-end">
              <button
                onClick={() => setnav(!nav)}
                className={`d-md-none ${
                  nav === true ? "hamburger" : "hamburger-2"
                }`}
              >
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppNavbar;
