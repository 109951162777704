import React from "react";
import { Col, Row } from "react-bootstrap";
import mailIcon from "../../assets/images/svg/mailIcon.svg";

const WorkWithUs = () => {
  return (
    <div className="py-5">
      <div className="container">
        <Row className="align-items-center">
          <Col className="text-center text-lg-start" lg={6}>
            <div>
              <h2 className="ff_petrona mb-0 text-white fs_80 fw-normal">
                Contact <span className="fw-bold"> Our HR</span>
              </h2>
              <div>
                <div className="">
                  <p className="mb-0 ff_inter fw-light text-white fs_16 opacity_70">
                    We are here to assist you with all your HR-related queries
                    and concerns. Whether you are a current employee, a
                    prospective candidate, or simply seeking information, our HR
                    team is dedicated to providing the support you need.
                  </p>
                  <div className="d-flex align-items-center gap-2 mt-4 justify-content-center justify-content-lg-start">
                    <div className="icons-bg d-flex align-items-center justify-content-center">
                      <img loading="lazy" src={mailIcon} alt="mailIcon" />
                    </div>
                    <a
                      className="text-white text-decoration-underline ff_baijam fs_18"
                      href="mailto:hr@bambooruts.com"
                    >
                      hr@bambooruts.com
                    </a>
                  </div>
                  <p className="mb-0 ff_inter fw-normal fs_16 text-white opacity_80 mt-3">
                    For enquiries and applications , please send your resume
                    along with a detailed cover letter at{" "}
                    <span>
                      <a
                        className="text-white fw-medium"
                        href="mailto:hr@bambooruts.com"
                      >
                        hr@bambooruts.com
                      </a>
                    </span>
                     
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="mt-4">
              <div className="values-environment-bg">
                <h3 className="ff_petrona fw-bold fs_32 text-white mb-0">
                  Our Values
                </h3>
                <p className="mb-0 text-white fw-normal fs_16 opacity_70 ff_inter">
                  At Bamboo Ruts, our values are deeply rooted in integrity and
                  reliability. We are a team of our word, committed to honesty
                  and transparency in all our interactions. Just as the root
                  system of bamboo provides stability and support. We believe in
                  setting realistic expectations and exceeding them, ensuring
                  that our clients can trust us to always act with integrity and
                  deliver on our promises.
                </p>
              </div>
              <div className="values-environment-bg mt-4">
                <h3 className="ff_petrona fw-bold fs_32 text-white mb-0">
                  Our Environment
                </h3>
                <p className="mb-0 text-white fw-normal fs_16 opacity_70 ff_inter">
                  At Bamboo Ruts, our environment is akin to the tranquility of
                  a forest, fostering creativity and collaboration. Like the
                  interconnected roots of bamboo, our team is bound together,
                  united in our mission to grow our organization rapidly. With a
                  cool and inviting atmosphere, we cultivate innovation and
                  teamwork, allowing ideas to flourish and initiatives to take
                  root. Together, we thrive in an environment where unity and
                  growth intertwine harmoniously.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WorkWithUs;
