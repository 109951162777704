import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import creative from "../assets/images/png/creative.jpeg";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const CreativePersonalization = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">1. Custom Graphic Design </span>We
          create bespoke graphic designs tailored to your brand’s unique
          personality and audience preferences. Our designs are crafted to make
          a lasting impression and enhance your brand’s visual appeal.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Logo Design</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Identity Design
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Marketing Collateral (Brochures, Flyers, Posters)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Custom Illustrations
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">2. Personalized Visual Content </span>{" "}
          Engage your audience with visually stunning content that speaks
          directly to them. We design personalized visual content that aligns
          with your brand messaging and captures the essence of your brand.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Graphics
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Website Banners</li>
          <li className="text-white ff_inter fs_16 mt-1">Infographics</li>
          <li className="text-white ff_inter fs_16 mt-1">Email Graphics</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">3. Data-Driven Design Strategy </span>
          Our design process is informed by data and insights to ensure that
          each graphic element is effective and impactful. We analyze audience
          behavior and preferences to create designs that resonate.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Audience Research</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Design Trends Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            A/B Testing & Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            4. Dynamic Visual Personalization{" "}
          </span>
          Leverage dynamic design elements that adapt to individual user
          preferences and behaviors. Our dynamic visual personalization services
          ensure that your audience receives a tailored visual experience.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1"> Dynamic Ad Design</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Personalized Web Graphics
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            {" "}
            Customized User Interfaces
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Interactive Visual Elements
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">5. Brand Consistency </span>
          Maintain a consistent visual identity across all platforms and
          materials. We ensure that every design element reflects your brand’s
          core values and aesthetics.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Visual Style Guide</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Asset Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Consistent Cross-Platform Design
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Cohesive Brand Messaging
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">6. Collaborative Design Process </span>{" "}
          Work closely with our team to bring your vision to life. Our
          collaborative design process ensures that your input is valued and
          integrated into the final designs.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Design Workshops</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Creative Brainstorming Sessions
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Feedback Integration
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Iterative Design Process
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Innovative Design Solutions </span>{" "}
          Stay ahead of the curve with innovative design solutions that set your
          brand apart. We incorporate the latest design trends and technologies
          to create cutting-edge visuals.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            3D Graphics & Animation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Augmented Reality (AR) Design
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Motion Graphics</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Interactive Infographics
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">8. High-Quality Deliverables </span>{" "}
          Receive high-quality design deliverables that meet your exact
          specifications. We ensure that every design is polished, professional,
          and ready to make an impact.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Print-Ready Files</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Web-Optimized Graphics
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Scalable Vector Graphics
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            High-Resolution Images
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Transform Your Visual Identity with Tailored Graphic Design"
          mainImg={creative}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we believe that personalization is the key to creating compelling and memorable visual experiences. Our creative personalization services in graphic designing are designed to craft unique visuals that resonate with your audience, reflecting your brand’s identity and values in every design element."
          mainHeading="Creative Personalization (Graphic Designing) Services at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="pt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Creative Excellence",
              para: `Our team of talented designers is dedicated to delivering creative excellence in every project.`,
            },
            {
              head: "Tailored Solutions",
              para: `We offer personalized design solutions that align with your brand’s unique needs and goals.`,
            },
            {
              head: "Strategic Approach",
              para: `Our designs are backed by strategic insights and data-driven methodologies to ensure effectiveness.`,
            },
            {
              head: "Client Collaboration",
              para: `We prioritize collaboration with our clients, ensuring your vision is at the forefront of our design process.`,
            },
            {
              head: "Innovation & Quality",
              para: `We leverage the latest design trends and technologies to deliver innovative and high-quality designs.`,
            },
          ]}
          journeyHead="Elevate Your Visual Identity Today"
          brandJourney="Ready to transform your brand’s visual identity with personalized graphic design? Contact Bamboo Ruts Digital Marketing Agency to learn more about our creative personalization services and how we can help you create visually compelling and impactful designs."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default CreativePersonalization;
