import React from "react";
import AppNavbar from "../../Navbar/AppNavbar";
import panda from "../../../assets/images/svg/pandaHanging.svg";
import firstThing from "../../../assets/images/png/firstThing.jpg";
import BlogDetailHero from "./BlogDetailHero";

const BlogDetails = () => {
  return (
    <div>
      <AppNavbar
        wUnset="width-unset"
        display_blog={
          <img
          loading="lazy"
            className={`panda-hanging position-absolute`}
            src={panda}
            alt=""
          />
        }
        blog_bold="font-nav"
      />
      <BlogDetailHero seoImg={firstThing} />
      <div className="py-5">
        <div className="container">
          <h2 className="clr_dark_blue ff_inter fs_48 fw-bold mb-0">
            First Thing you should know if you want to develop your personality
          </h2>
          <p className="clr_dark_blue ff_inter fs_20 fw-bold mb-0 mt-2">
            By Gaurav January 5, 2024
          </p>
          <div>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              Here are the free personality development tips by our expert
              trainer. If you are wondering how to develop your personality,
              first thing you should start with is to love yourself!
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              I come across so many people who struggle to find their real
              potential, capabilities and strengths. People do not understand
              their own likes, dislikes, wants and things that wish from their
              lives. This is probably because we are so occupied in our life
              listening to other’s judgments that we overlook our inner voice of
              our soul. We weigh more to what others think “we can’t do” and
              allow them to control our thoughts and even dreams. Then we
              hesitate to cross the boundaries defined by criticism and restrict
              our circle of capabilities within the same. For that moment until
              we allow us to experiment new things and deny the dominance of
              others thought we find ourselves in a “safe zone”.
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              As we give ourselves to come out of this safe zone and start
              dreaming what I want to do, you actually start to explore you can
              do more things than others expected and that even you were unaware
              of. But it takes a courage, a motivation and an ability to deny
              what you think you can’t do. And this is obviously not so easy to
              crack.
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              In order to change your personality you have to challenge your own
              beliefs of about yourself. You have to give yourself a new chance
              of trying new things despite your own heart says “I can’t” you
              need to replace it with “I want to and I will“. But to do so one
              need to bring a lot of positivity and confidence in oneself. Now
              the question is from where to start… Answer is by loving yourself
              the way you are!
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              Remember any best day of your life where your parents were so
              happy and proud for what you achieved. Or remember any incident
              where you had won any competition. Remember any small help offered
              by you to a stranger earned you a good compliment that day. When
              you think about any of such events and compliments you’d received
              a long days back, it has power to give your smile back for a
              moment.
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              When you focus on good memories and appreciations instead of
              criticism; you feel you are worthy. You feel you deserve a good
              life, a success, wealth, name, fame and everything that’s in your
              dream. This feeling is very important to increase your
              Self-esteem. Self-esteem means what you think of yourself. Your
              first thoughts before you start any work – whether you say “ I can
              Do it” or “ I don’t think I can do it” or “May be, I should try”.
              The own estimation of your potential has a huge impact on your
              confidence level and ultimately on your performance.
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              But, as it seems so easy to say, it isn’t so simple in reality and
              hence the question remains how to develop a personality!
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              Even scientifically it’s been proven that your brain spends more
              energy in analyzing negative incidents than positive ones. For
              instance, if I ask you to recall your prominent incidents in life
              in last year, most of things could be associated with negative
              feelings or events.
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              These negative setbacks are three times more powerful than
              positive memories.
            </p>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              Normal brain spends extra energy to scan dangers, threats,
              warnings, fear and negative events more. Since an ancient era, our
              survival was contingent on our ability of anticipating these
              things. But we can surely change the way our brain works. And this
              is the key of developing our personality.
            </p>
            <p className="ff_inter fs_18 fw-bold clr_dark_blue mb-0 mt-4">
              You can work consciously to rewire your brain energy to focus more
              on positive memories. Start with simple daily practices. Here are
              some tips to develop a personality.
            </p>
            <div>
              <p className="ff_inter fs_18 fw-bold clr_dark_blue mb-0 mt-3">
                Start you day by complementing to yourself
              </p>
              <p className="ff_inter fw-light fs_16 clr_dark_grey mt-2">
                “ I look good today’’ , “I think my boss is going to like my
                presentation today”, “I think when I will complete my
                performance on a stage I’m going to hear a huge round of
                applaud” These self compliments and affirmations will help to
                kill your fear and inferior complex if any.
              </p>
            </div>
            <div>
              <p className="ff_inter fs_18 fw-bold clr_dark_blue mb-0 mt-4">
                Create at least 3 good memories each day
              </p>
              <p className="ff_inter fw-light fs_16 clr_dark_grey mt-2">
                It could be as small as making someone smile on your joke or to
                compliment someone for their looks or achievement. It could be
                just sending an honest messages to someone you care. These may
                not take much of your time but could bring you a great sense of
                accomplishment of good deeds.
              </p>
            </div>
            <div>
              <p className="ff_inter fs_18 fw-bold clr_dark_blue mb-0 mt-4">
                Learn to share, care and help others
              </p>
              <p className="ff_inter fw-light fs_16 clr_dark_grey mt-2">
                The truth is if you want to be happy and collect the good
                memories, the only way of getting it is by giving it. So don’t
                restrict your hands while helping someone or sharing, loose it
                up. Go further steps to illustrate your loved ones how much you
                care and let them know the best of you because they all are
                going to realize it one day. And then, they will be the ones who
                stand by you at your worst!
              </p>
            </div>
            <div>
              <p className="ff_inter fs_18 fw-bold clr_dark_blue mb-0 mt-4">
                Gift yourself a beautiful journal and write the best moments of
                your life in it
              </p>
              <p className="ff_inter fw-light fs_16 clr_dark_grey mt-2">
                In order to make conscious efforts of re-enforcing optimism in
                you, you can start listing the good things happening in your
                life. Even simple complements like “looking good today” and
                proud moments of achievements can be included in it. Try to
                write it almost every day. Not only the good things happened to
                you but the good things you created for others should be
                mentioned.
              </p>
            </div>
            <p className="ff_inter fw-light fs_16 clr_dark_grey mt-3">
              This journal will act as a savior in your dark days. Especially
              after few years when your memories fade away, you will be
              surprised to witness them again. And then one day when someone
              will try to pull you down by shattering your confidence with
              his/her harsh criticism, you will have enough reasons to bounce
              back!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
