import React, { useState } from "react";
import "./WhatAreClientSay.css";
import bambooSticks from "../../assets/images/png/bambooSticks.webp";
import maxdent from "../../assets/images/png/maxdent.png";
import finVedas from "../../assets/images/png/finVedas.avif";
import rdm from "../../assets/images/png/rdmSchool.jpg";
import panda from "../../assets/images/svg/panda.svg";
import prevArrow from "../../assets/images/svg/btn-prev-arrow.svg";
import nextArrow from "../../assets/images/svg/btn-next-arrow-white.svg";
import ratingStars from "../../assets/images/svg/ratingStars.svg";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";

const WhatAreClientSay = () => {
  const [sliderData, setSliderData] = useState([
    {
      logo: maxdent,
      width: "68px",
      height: "48px",
      company: "Maxdent Hisar",
      date: "2023-01-01",
      review: `Bamboo Ruts transformed my dental clinic's online presence. Their tailored strategies increased patient engagement and visibility. Highly recommended for any clinic looking to enhance their marketing efforts and grow their practice.`,
    },
    {
      logo: finVedas,
      width: "58px",
      height: "48px",
      company: "FinVedas ",
      date: "2023-01-01",
      review: `Bamboo Ruts has been instrumental in catapulting Finvedas to new heights in the competitive EdTech landscape. Their innovative marketing strategies have amplified our brand's visibility and attracted a wider audience to our trading training platform. Highly recommended for startups seeking to accelerate their growth journey.`,
    },
    {
      logo: rdm,
      width: "58px",
      height: "58px",
      company: "RDM sarastwati Public school ",
      date: "2023-01-01",
      review: `Opting for marketing services from Bamboo Ruts has proven to be a game-changer for our business. Their strategic approach and innovative tactics have propelled our growth to new heights. With their guidance, we've seen a significant increase in leads and conversions, and our brand awareness has skyrocketed`,
    },
  ]);
  const button = React.useRef();
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="py-5 what-our-client-say position-relative z-1">
      <div className="d-none d-lg-block">
        <img
          loading="lazy"
          className="position-absolute bamboo-sticks"
          src={bambooSticks}
          alt="bambooSticks"
        />
        <img
          loading="lazy"
          className="position-absolute panda overflow-hidden "
          src={panda}
          alt="panda"
        />
      </div>
      <div className="container position-relative z-1">
        <Row>
          <Col lg={8}>
            <div>
              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <h2 className="mb-0 ff_petrona fs_64 fw-light">
                  What our <span className="fw-bold d-block"> Clients say</span>
                </h2>
                <p className="ff_inter fs_16 clr_light_black client-say-para px-2 text-center text-md-start px-md-0 mt-3 mt-sm-0">
                  Testimonials speak volumes. Tailored strategies drive success.
                  Innovation propels growth. Accelerate your journey with us.{" "}
                </p>
              </div>

              <Slider className="mt-md-5 mt-2" {...settings} ref={button}>
                {sliderData.map((value, index) => (
                  <div className="px-2" key={index}>
                    <div className="client-cards p-4 h-100 cursor_pointer">
                      <div className="d-flex align-items-center gap-3">
                        <div className="text-center">
                          <img
          loading="lazy"
                            style={{
                              width: `${value.width}`,
                              height: `${value.height}`,
                            }}
                            src={value.logo}
                            alt="maxdent"
                          />
                        </div>
                        <div>
                          <p className="ff_lato fw-semibold fs_18 mb-0">
                            {value.company}
                          </p>
                          <p className="mb-0 ff_lato fs_16">{value.date}</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        <img
          loading="lazy" src={ratingStars} alt="rating" />
                      </div>
                      <p className="mb-0 ff_lato fs_16 opacity_80 mt-2">
                        {value.review}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="mt-4 d-flex align-items-center justify-content-center justify-content-md-start gap-2">
                <button
                  onClick={() => button.current.slickPrev()}
                  className="btn-slider-prev d-flex align-items-center justify-content-center"
                >
                  <img
          loading="lazy" src={prevArrow} alt="" />
                </button>
                <button
                  onClick={() => button.current.slickNext()}
                  className="btn-slider-next d-flex align-items-center justify-content-center"
                >
                  <img
          loading="lazy" src={nextArrow} alt="" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhatAreClientSay;
