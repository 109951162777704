import React, { useEffect, useState } from "react";
import "./BlogHero.css";
import bambooSticks from "../../assets/images/png/bambooSticks.webp";
import pandaWithBamboo from "../../assets/images/png/pandaWithBamboo.png";
import panda from "../../assets/images/svg/panda.svg";

const BlogHero = () => {
  const [scrolldown, setScrollDown] = useState(false);
  useEffect(() => {
    if (scrolldown) {
      const timer = setTimeout(() => {
        setScrollDown(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [scrolldown]);
  return (
    <div className="flex-grow-1 d-flex flex-column justify-content-center z-1 py-5 py-lg-0">
      <div className="d-none d-lg-block">
        <img
          loading="lazy"
          className="position-absolute bamboo-sticks"
          src={bambooSticks}
          alt="bambooSticks"
        />
        <img
          loading="lazy" className="position-absolute panda " src={panda} alt="panda" />
      </div>
      <div className="container position-relative ">
        <div className="text-center d-lg-none">
          <img
          loading="lazy" className="w-50" src={pandaWithBamboo} alt="panda" />
        </div>
        <div className="text-center text-lg-start">
          <h1 className="ff_petrona fs_70">
            Stay Updated with <br />{" "}
            <span className="clr_green_2 fw-800"> Bamboo Roots</span>
          </h1>
          <p className="hero_para_width mx-auto mx-lg-0 fs_18">
            Explore how Bamboo Roots came to be, our mission, and our commitment
            to delivering top-tier digital marketing solutions tailored to your
            brand's success..
          </p>
        </div>
        <div
          onClick={() => setScrollDown(true)}
          className="dot-border d-flex justify-content-center position-absolute cursor_pointer"
        >
          <div className=" postion-relative">
            <div
              className={`green-dot position-absolute  ${
                scrolldown === true ? "dot-active" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHero;
