import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import GetInTouch from "../components/getInTouch/GetInTouch";
import AppFooter from "../components/footer/AppFooter";
import PrivacyPolicy from "../components/Privacy/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <AppNavbar wUnset="width-unset" />
      <PrivacyPolicy />
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
