import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const ServiceDetailHero = ({ mainHeading, mainImg, subHeading, mainPara }) => {
  return (
    <div className="py-5">
      <Container>
        <h2
          style={{ maxWidth: "900px" }}
          className="text-center ff_petrona mx-auto fs_40"
        >
          {mainHeading}
        </h2>
        <Row className="mt-md-5 mt-4 pt-md-5 align-items-center flex-column-reverse flex-md-row">
          <Col md={6}>
            <div className="mt-4 text-center mt-md-0 text-md-start">
              <h3 className="ff_petrona fs_32">{subHeading}</h3>
              <p className="mb-0 ff_inter fs_18">{mainPara}</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-center">
              <img
                style={{ maxWidth: "400px", height: "350px" }}
                className="w-100 object-fit-cover rounded-3"
                src={mainImg}
                alt="pandaSitting"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceDetailHero;
