import React from "react";

const TermAndConditions = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h2 className="ff_inter fs_32 fw-bold clr_dark_blue mb-0 mt-4">
          Privacy Policy
        </h2>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
          This is a place to describe your Return and Refund Policy to buyers.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
          A Return and Refund policy usually consists of:
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
          In line with providing our customers with the best quality and client
          experience, we have established a considerate cancellation & refund
          policy.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          We plan each project by dividing work into milestones.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          All projects begin with a complete discovery/analysis and the creation
          of a scope of work document. This is done to make sure that both
          parties have a complete understanding of the work required and thus
          eliminate the potential for project cancellations, reversals, or
          disputes. As there is time spent to achieve every milestone and
          complete every module, a refund is not possible for work already
          completed.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          In the event of a project terminated on a mutual basis, the client has
          control of all completed work, and any payment for further development
          will become void. No previous payment or deposit would be refunded.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          No payment will be refunded once the client approves mock-up designs
          and we move into the development phase of the project.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          There are no partial refunds for projects mid-way through a milestone
          phase. No cancellations are entertained for services that our
          marketing team has offered on special occasions. These are
          limited-time offers and cannot be canceled.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          SEO and digital marketing packages are not refundable, but the client
          may cancel with a notice written 30 days in advance.
        </p>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
          We do not offer refunds on deposits or payments for projects that are
          abandoned or lay dormant for more than 30 days.
        </p>
      </div>
    </div>
  );
};

export default TermAndConditions;
