import React from "react";
import personWithLaptop from "../../../assets/images/png/personWithLaptop.png";

const BlogDetailHero = ({ seoImg }) => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="detail-hero-img overflow-hidden">
          <img
          loading="lazy"
            className="detail-hero-img w-100 rounded-2"
            src={seoImg}
            alt="heroImg"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogDetailHero;
