import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import WorkWithUs from "../components/workWithUs/WorkWithUs";

const WorkWithUsPage = () => {
  return (
    <div>
      <div className="contact-bg position-relative z-2 pt-2">
        <div className="footer-overlay position-absolute"></div>
        <AppNavbar
          textWhite="text-white"
          wUnset="width-unset"
          opacity_100_work="opacity-100"
          opacity_70="opacity_70"
        />
        <WorkWithUs />
        <AppFooter />
      </div>
    </div>
  );
};

export default WorkWithUsPage;
