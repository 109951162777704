import React from "react";
import { Col, Row } from "react-bootstrap";
import callIcon from "../../assets/images/svg/callIcon.svg";
import linkedIn from "../../assets/images/svg/linkedIn.svg";
import faceBook from "../../assets/images/svg/faceBook.svg";
import instagram from "../../assets/images/svg/instagram.svg";
import twitter from "../../assets/images/svg/twitter.svg";
import mailIcon from "../../assets/images/svg/mailIcon.svg";
import mapPin from "../../assets/images/svg/mapPin.svg";
import panda from "../../assets/images/png/pandaWearingheadPhone.png";

const Contact = () => {
  return (
    <div className="py-5">
      <div className="container">
        <Row className="align-items-center">
          <Col lg={6}>
            <div>
              <h2 className="ff_petrona mb-0 text-white fs_80">
                Get in <span className="fw-bold"> Touch</span>
              </h2>
              <div>
                <div className="d-flex align-md-items-center justify-content-center flex-sm-row flex-column gap-4 gap-sm-5 mt-xl-5 mt-4">
                  <div className="">
                    <div className="d-flex align-items-center gap-2">
                      <div className="icons-bg d-flex align-items-center justify-content-center">
                        <img
                          loading="lazy"
                          style={{ width: "30px", height: "40px" }}
                          src={mapPin}
                          alt="call"
                        />
                      </div>
                      <a
                        target="_blank"
                        href="https://maps.app.goo.gl/EuX7gYvRfaFsEjGRA"
                        className="mb-0 ff_poppins fw-light text-white fs_16"
                      >
                        CO-OFFIZ: Coworking Space in Golf Course Extension Road,
                        Gurugram
                      </a>
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-4">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/101997200/admin/feed/posts/"
                      >
                        <div className="social-icons-bg d-flex align-items-center justify-content-center">
                          <img loading="lazy" src={linkedIn} alt="linkedIn" />
                        </div>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/profile.php?id=61559857974344"
                      >
                        <div className="social-icons-bg d-flex align-items-center justify-content-center">
                          <img loading="lazy" src={faceBook} alt="facebook" />
                        </div>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/bamboo.ruts/"
                      >
                        <div className="social-icons-bg d-flex align-items-center justify-content-center">
                          <img loading="lazy" src={instagram} alt="instagram" />
                        </div>
                      </a>
                      <a target="_blank" href="https://twitter.com/BambooRuts">
                        <div className="social-icons-bg d-flex align-items-center justify-content-center">
                          <img loading="lazy" src={twitter} alt="twitter" />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center gap-2">
                      <div className="icons-bg d-flex align-items-center justify-content-center">
                        <img
                          loading="lazy"
                          style={{ width: "20px", height: "20px" }}
                          src={callIcon}
                          alt="call"
                        />
                      </div>
                      <a
                        href="tel:+91 7300880028"
                        className="mb-0 ff_poppins fw-light text-white fs_16"
                      >
                        +91 7300880028
                      </a>
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-4">
                      <div className="icons-bg d-flex align-items-center justify-content-center">
                        <img
                          loading="lazy"
                          style={{ width: "20px", height: "20px" }}
                          src={mailIcon}
                          alt="call"
                        />
                      </div>
                      <a
                        href="mailto:info@bambooruts.com"
                        className="mb-0 ff_poppins fw-light text-white fs_16"
                      >
                        info@bambooruts.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="text-center">
              <img loading="lazy" className="w-75" src={panda} alt="panda" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
