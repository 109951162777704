import React from "react";
import "./Aboutus.css";
import { Col, Row } from "react-bootstrap";
import teamPic from "../../../assets/images/png/pandaGroup.webp";

const AboutUs = () => {
  return (
    <div className="py-5">
      <div className="container pt-5 mt-5">
        <Row className="align-items-center flex-column-reverse flex-md-row">
          <Col md={6}>
            <div className="text-center mt-4 text-md-start">
              <h2 className="mb-0 ff_petrona fs_48 text-white fw-light">
                About <span className="fw-800"> Us?</span>
              </h2>
              <p className="ff_inter fs_20 text-white opacity_90">
                At Bamboo Ruts, we are more than just a digital marketing agency
              </p>
              <p className="ff_inter fs_16 mb-0 text-white opacity_70 fw-light">
                we are storytellers, innovators, and partners in your business
                journey. Our mission is simple yet powerful: to convey your
                business's emotion to your customer through strategic digital
                marketing solutions.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <img
                loading="lazy"
                className="w-100 panda-team-img rounded-2"
                src={teamPic}
                alt="team"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
