import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import Contact from "../components/ContactPage/Contact";
import AppFooter from "../components/footer/AppFooter";


const ContactPage = () => {
  return (
    <div>
      <div className="contact-bg position-relative z-2 pt-2">
        <div className="footer-overlay position-absolute"></div>
        <AppNavbar
          textWhite="text-white"
          wUnset="width-unset"
          opacity_100="opacity-100"
          opacity_70="opacity_70"
        />
        <Contact />
        <AppFooter />
      </div>
    </div>
  );
};

export default ContactPage;
