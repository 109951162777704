import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import seo from "../assets/images/png/seo.png";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const SeoPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">1. SEO Audit & Analysis </span> Our SEO
          experts conduct a thorough audit of your website to identify
          strengths, weaknesses, and opportunities for improvement. This
          analysis forms the foundation of a customized SEO strategy tailored to
          your business needs.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Technical SEO Audit
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            On-Page SEO Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Off-Page SEO Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Competitor Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Comprehensive Reporting
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">2. Keyword Research & Strategy </span>{" "}
          Effective SEO starts with understanding what your target audience is
          searching for. We perform in-depth keyword research to identify
          high-traffic, relevant keywords that align with your business
          objectives.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Keyword Identification
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Search Volume & Competition Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Long-Tail Keyword Strategy
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Keyword Mapping & Targeting
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">3. On-Page SEO Optimization </span>
          Optimize your website's content and structure to improve its relevance
          and usability for both search engines and users. Our on-page SEO
          services ensure your website meets the latest SEO best practices.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Meta Tags Optimization (Title, Description, Headers)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Optimization (Keyword Integration, Readability)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            URL Structure Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Internal Linking Strategy
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Image Optimization (Alt Tags, Compression)
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Technical SEO</span>
          Ensure your website is technically sound and search engine-friendly.
          We address and resolve technical issues that could hinder your
          website's performance in search engine results.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Site Speed Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Mobile-Friendliness
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            {" "}
            SSL Implementation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            XML Sitemap & Robots.txt Setup
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Structured Data & Schema Markup
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            5. Content Creation & Optimization{" "}
          </span>
          Quality content is essential for SEO success. We create and optimize
          content that is engaging, informative, and optimized for search
          engines, helping you attract and retain visitors.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Blog Posts & Articles
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            {" "}
            Website Copywriting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Landing Page Content
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Gap Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Calendar Development
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">6. Link Building </span> Boost your
          website's authority and search engine rankings through ethical and
          effective link-building strategies. We focus on acquiring high-quality
          backlinks from reputable sources.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Outreach & Relationship Building
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Guest Blogging</li>
          <li className="text-white ff_inter fs_16 mt-1">Content Promotion</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Directory Submissions
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Broken Link Building
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Local SEO </span> Enhance your
          visibility in local search results and attract customers from your
          geographic area. Our local SEO services ensure your business appears
          in relevant local searches.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Google My Business Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Local Citations & Directory Listings
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Localized Content Creation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Online Reviews Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Local Keyword Optimization
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">8. SEO Monitoring & Reporting </span>{" "}
          Track your SEO performance with our ongoing monitoring and detailed
          reporting services. We provide regular updates and insights to help
          you understand your progress and make informed decisions.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Keyword Rankings Tracking
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Website Traffic Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Conversion Tracking
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Monthly Performance Reports
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Strategy Adjustments
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Elevate Your Online Presence with Expert SEO Services"
          mainImg={seo}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we specialize in search engine optimization (SEO) services that enhance your online visibility and drive organic traffic to your website. Our comprehensive SEO strategies are designed to help your business achieve higher search engine rankings, attract more qualified leads, and ultimately, boost your revenue."
          mainHeading=" Search Engine Optimization (SEO) Services at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="py-5 mt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Expertise & Experience",
              para: `Our team of SEO specialists brings years of experience and proven success to your SEO campaigns.`,
            },
            {
              head: "Customized Strategies",
              para: `We develop tailored SEO strategies based on your unique business goals and market conditions.`,
            },
            {
              head: "Transparent Reporting",
              para: `We provide clear, detailed reports to keep you informed about your SEO performance and progress.`,
            },
            {
              head: "Results-Driven",
              para: `Our primary focus is on delivering tangible results that drive traffic, leads, and revenue for your business.`,
            },
            {
              head: "Client-Centric Approach",
              para: `We work closely with you to understand your needs and ensure our strategies align with your objectives.`,
            },
          ]}
          brandJourney="Ready to take your online presence to the next level? Contact Bamboo Ruts Digital Marketing Agency to learn more about our SEO services and how we can help you achieve higher search engine rankings and drive more organic traffic to your website."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default SeoPage;
