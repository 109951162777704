import React from "react";
import teamPic from "../../../assets/images/png/team.png";
import { Col, Row } from "react-bootstrap";

const WhatWeDo = () => {
  return (
    <div className="py-5">
      <div className="container pb-5 mb-5">
        <Row className="align-items-center pb-5">
          <Col md={6}>
            <div>
              <img
          loading="lazy" className="w-100" src={teamPic} alt="team" />
            </div>
          </Col>
          <Col md={6}>
            <div className="text-center mt-4 text-md-start">
              <h2 className="mb-0 ff_petrona fs_48 text-white fw-light">
                What <span className="fw-800"> We Do?</span>
              </h2>
              <p className="ff_inter fs_20 text-white opacity_90 mb-0 fw-light mt-2">
                At Bamboo Ruts, we specialize in crafting unique and data-driven
                digital marketing strategies that help businesses establish a
                robust foundation for sustainable growth. Just like the
                resilient roots of bamboo, our approach ensures your brand
                thrives and reaches new heights. From personalized campaigns to
                innovative solutions, we focus on achieving real results that
                drive your business forward. Join us in building a powerful
                online presence that stands out from the rest. Let's grow
                together!
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhatWeDo;
