import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import fightingPanda from "../assets/images/png/fightingPanda.webp";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const NtiermarketingPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            1. Market Segmentation & Analysis{" "}
          </span>
          Gain a deep understanding of your target markets across different city
          tiers. We analyze the demographics, behaviors, and preferences of your
          audience to create effective, tier-specific marketing strategies.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">City Tier Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Audience Segmentation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Demographic Research
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Consumer Behavior Insights
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            2. Customized Marketing Strategies{" "}
          </span>{" "}
          Develop bespoke marketing strategies tailored to the unique
          characteristics of each city tier. Our strategies ensure that your
          brand’s message is relevant and impactful in every market.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Tier 1 City Strategies
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Tier 2 City Strategies
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Tier 3 City Strategies
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Integrated Campaign Planning
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">3. Localized Content Creation </span>
          Create compelling, localized content that resonates with your target
          audience in each city tier. Our content is designed to engage and
          convert, reflecting the cultural and regional nuances of your market.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Localized Blog Posts & Articles
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            City-Specific Social Media Content
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Regional Video Production
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Community-Focused Storytelling
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            4. Multi-Channel Marketing Campaigns{" "}
          </span>
          Reach your audience across multiple channels with tailored campaigns
          for each city tier. We ensure consistent and relevant messaging across
          all platforms.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Email Marketing Campaigns
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Advertising
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Local Search Engine Marketing (SEM)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            City-Specific Display Ads
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">5. City-Specific Display Ads </span>
          Generate high-quality leads and optimize the conversion process for
          each city tier. Our strategies are designed to attract and nurture
          leads, driving them through your sales funnel.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Localized Landing Pages
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            City-Specific Lead Magnets
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Personalized Email Workflows
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Conversion Rate Optimization (CRO)
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            6. Local SEO & Directory Listings{" "}
          </span>
          Enhance your online visibility with local SEO strategies and directory
          listings. We optimize your presence in local searches, ensuring that
          your business is easily found by your target audience.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Local Keyword Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Google My Business Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            City-Specific Directory Listings
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Online Review Management
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            7. Performance Tracking & Analytics{" "}
          </span>
          Measure the success of your N-Tier marketing campaigns with detailed
          performance tracking and analytics. We provide actionable insights to
          help you refine and improve your strategies.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            KPI Tracking & Reporting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            City Tier Performance Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">ROI Measurement</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Data-Driven Optimization
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            8. Continuous Strategy Refinement{" "}
          </span>
          Stay ahead of the competition with continuous strategy refinement. We
          monitor market trends and adjust your marketing efforts to ensure
          ongoing success.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Ongoing Campaign Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Regular Performance Reviews
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Trend Analysis & Adaptation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Strategy Updates & Refinements
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Tailored Marketing Strategies for Diverse City Tiers"
          mainImg={fightingPanda}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we understand that each city tier—whether it's Tier 1, Tier 2, or Tier 3—has unique characteristics and marketing needs. Our N-Tier marketing services are specifically designed to address these differences, providing tailored strategies that resonate with audiences across diverse city tiers."
          mainHeading="N-Tier Marketing Services for Every Tier City at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="pt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Expertise in Diverse Markets",
              para: `Our team has extensive experience in crafting marketing strategies for diverse city tiers, ensuring that your brand connects with the right audience.`,
            },
            {
              head: "Tailored Solutions",
              para: `We develop customized marketing strategies that address the unique needs and characteristics of each city tier.`,
            },
            {
              head: "Data-Driven Insights",
              para: `Our strategies are informed by data and analytics, ensuring effective and efficient marketing efforts.`,
            },
            {
              head: "Client-Focused Approach",
              para: `We prioritize your success and work closely with you to create strategies that align with your business goals and market conditions.`,
            },
            {
              head: "Proven Results",
              para: `Our N-Tier marketing services are designed to drive tangible results, from increased visibility to higher conversion rates and revenue growth.`,
            },
          ]}
          journeyHead="Elevate Your Marketing Across City Tiers Today"
          brandJourney="Ready to optimize your marketing efforts for every city tier? Contact Bamboo Ruts Digital Marketing Agency to learn more about our N-Tier marketing services and how we can help you connect with diverse audiences and achieve your business objectives."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default NtiermarketingPage;
