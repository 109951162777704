import React, { useState } from "react";
import "./ServiceExample.css";
import pandaWithPc from "../../assets/images/png/pandaSittingWithLaptop.png";
import seo from "../../assets/images/png/seo.png";
import viewAllBox from "../../assets/images/png/viewAllBox.png";
import branding from "../../assets/images/png/branding.webp";
import marketing from "../../assets/images/png/marketing.webp";
import creative from "../../assets/images/png/creative.jpeg";
import fightingPanda from "../../assets/images/png/fightingPanda.webp";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ServiceExamples = () => {
  const [data, setData] = useState([
    {
      img: branding,
      service: "Branding",
    },
    {
      img: marketing,
      service: "Influencer Marketing",
    },
    {
      img: seo,
      service: "SEO",
    },
    {
      link: "/service",
      img: viewAllBox,
      service: "",
    },
  ]);

  return (
    <div className="pb-5">
      <div className="pt-lg-5 pb-5 mt-lg-5">
        <div className="container px-0 py-lg-5 my-lg-5">
          <Row className="pb-5 pb-md-0">
            {data.map((value, index) => (
              <div
                key={index}
                className="col-md-3 col-6 p-0 position-relative hover-effect cursor_pointer"
              >
                {value.link ? (
                  <Link to={value.link}>
                    <div className="service-card-overlay"></div>
                    <div className="h-100">
                      <img
                        loading="lazy"
                        className="w-100 h-100"
                        src={value.img}
                        alt=""
                      />
                      <p
                        className={`mb-0 ${value.class} ff_baijam fw-bold services-font-size text-white service-examples position-absolute text-nowrap`}
                      >
                        {value.service}
                      </p>
                    </div>
                  </Link>
                ) : (
                  <>
                    <div className="service-card-overlay"></div>
                    <div className="h-100">
                      <img
                        loading="lazy"
                        className="w-100 h-100"
                        src={value.img}
                        alt=""
                      />
                      <p
                        className={`mb-0 ${value.class} ff_baijam fw-bold services-font-size text-white service-examples position-absolute text-nowrap`}
                      >
                        {value.service}
                      </p>
                    </div>
                  </>
                )}
              </div>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ServiceExamples;
