import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import ServiceHero from "../components/Services/ServiceHero/ServiceHero";
import ServicesWeProvide from "../components/Services/ServicesWeProvide/ServicesWeProvide";
import GetInTouch from "../components/getInTouch/GetInTouch";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";

const ServicePage = () => {
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceHero />
      </header>
      <main>
        <div className="who-we-are-bg-service position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <ServicesWeProvide />
        </div>
      </main>
      <div className="get-in-touch-footer-bg-service pt-5 position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch display_none="d-none" />
        <AppFooter />
      </div>
    </div>
  );
};

export default ServicePage;
