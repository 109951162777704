import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import campain from "../assets/images/png/campain.webp";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const ImpactCampaingsPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">1. Campaign Strategy Development </span>{" "}
          A successful impact and awareness campaign starts with a solid
          strategy. We collaborate with you to define clear objectives, target
          the right audience, and develop a comprehensive plan to achieve your
          goals.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Goal Setting & KPI Definition
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Audience Research & Segmentation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Campaign Planning & Timeline
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Messaging & Content Strategy
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">2. Creative Campaign Design </span> Our
          team of creative experts designs compelling campaigns that capture
          attention and convey your message effectively across various channels.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Concept Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Visual Design & Branding
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Creation (Graphics, Videos, Articles)
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Multi-Channel Approach (Social Media, Email, Web, Print)
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">3. Social Media Campaigns </span>Harness
          the power of social media to amplify your message. We create and
          manage campaigns that generate buzz, foster engagement, and drive
          awareness.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Platform Selection & Strategy
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Scheduling & Posting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Community Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Analytics & Performance Tracking
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Influencer Partnerships </span>
          Leverage the reach and credibility of influencers to spread your
          message further. We identify and collaborate with influencers who
          align with your brand and campaign goals.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Influencer Identification & Outreach
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Partnership Negotiation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Campaign Execution</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Measurement
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            5. Public Relations & Media Outreach{" "}
          </span>
          Increase your campaign’s visibility through strategic public relations
          and media outreach. We help you build relationships with key media
          outlets and secure coverage that amplifies your impact.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Press Release Writing & Distribution
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            {" "}
            Media Pitching & Outreach
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Event Planning & Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Media Monitoring & Reporting
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">6. Community Engagement </span> Engage
          with your community to build trust and foster long-term relationships.
          We design initiatives that encourage participation and support from
          your target audience.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Community Events & Activations
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Online Community Building
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            User-Generated Content Campaigns
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Feedback & Engagement Analysis
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Monitoring & Evaluation </span> Track
          the effectiveness of your campaign with our comprehensive monitoring
          and evaluation services. We provide insights and recommendations to
          optimize your strategy and achieve better results.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Campaign Performance Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Impact Assessment</li>
          <li className="text-white ff_inter fs_16 mt-1">Regular Reporting</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Improvement Strategies
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Amplify Your Message, Create Lasting Impact"
          mainImg={campain}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we specialize in crafting impactful and awareness-driven campaigns that resonate with your audience and drive meaningful change. Our campaigns are designed to not only increase brand visibility but also to engage, educate, and inspire action."
          mainHeading="Impact and Awareness Campaign Services at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden pt-5 pb-4">
          <ProvidedServiceDetails serviceContent={serviceContent} />
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Holistic Approach",
              para: `We take a comprehensive approach to branding, considering every aspect of your brand to create a cohesive and powerful identity.`,
            },
            {
              head: "Expert Team",
              para: `Our team of experienced brand strategists, designers, and storytellers work collaboratively to bring your brand to life.`,
            },
            {
              head: "Tailored Solutions",
              para: `We customize our services to meet your specific needs and goals, ensuring that your brand stands out in your industry.`,
            },
            {
              head: "Innovative Strategies",
              para: `We use the latest tools and techniques to develop innovative branding strategies that drive results.`,
            },
            {
              head: "Client Partnership",
              para: `We see ourselves as partners in your success, working closely with you to understand your vision and bring it to fruition.`,
            },
          ]}
          brandJourney="Ready to build a brand that captivates and converts? Contact Bamboo Ruts Digital Marketing Agency to learn more about our branding services and how we can help you create a brand that truly represents your business."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default ImpactCampaingsPage;
