import React from "react";
import "./WhoWeAre.css";
import bambooSticks from "../../assets/images/png/bambooSticksLeft.webp";
import arrow from "../../assets/images/svg/rightArrow.svg";
import prevarrow from "../../assets/images/svg/btn-prev-arrow.svg";
import nextarrow from "../../assets/images/svg/btn-next-arrow-white.svg";
import panda from "../../assets/images/svg/pandaLeft.svg";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
  const button = React.useRef();
  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="py-5 who-we-are">
      <div className="container pe-0">
        <Row className="mt-xl-5  pt-4 pt-xl-5 pe-0 justify-content-between">
          <Col className="d-none d-lg-block" lg={2}>
            <img
              loading="lazy"
              className="position-absolute bamboo-sticks"
              src={bambooSticks}
              alt="bambooSticks"
            />
            <img
              loading="lazy"
              className="panda overflow-hidden position-absolute "
              src={panda}
              alt="panda"
            />
          </Col>
          <Col className="pe-0" lg={9}>
            <Row className="pe-0 align-items-center">
              <Col lg={5}>
                <div className="w-75 ms-lg-auto mx-auto">
                  <h2 className="text-white ff_petrona fs_64 text-center text-lg-start mb-4">
                    Who We <span className="fw-semibold">Are?</span>
                  </h2>
                  <div className="d-none d-lg-flex gap-2">
                    <button
                      onClick={() => button.current.slickPrev()}
                      className="btn-next d-flex align-items-center justify-content-center"
                    >
                      <img loading="lazy" src={prevarrow} alt="arrow" />
                    </button>
                    <button
                      onClick={() => button.current.slickNext()}
                      className="btn-next d-flex align-items-center justify-content-center"
                    >
                      <img loading="lazy" src={nextarrow} alt="arrow" />
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <Slider {...settings} ref={button}>
                  <div className="px-2">
                    <div className="slider-card h-100 p-3 d-flex flex-column justify-content-between">
                      <div>
                        <div>
                          <p className=" ff_baijam fw-medium fs_32 text-white mb-0">
                            Our Vision
                          </p>
                        </div>
                        <p className="ff_inter fs_20 my-1 text-white opacity_90">
                          Cultivating success through innovation and strategy.{" "}
                        </p>
                        <p className="ff_inter fs_16 mb-0 text-white opacity_70 fw-light">
                          We aim to propel our clients' businesses forward by
                          providing the perfect conditions for growth – from
                          strategic planning to innovative marketing techniques.
                        </p>
                      </div>
                      <div className=" mt-3">
                        <Link
                          className="d-flex align-items-center gap-2"
                          to={"/about"}
                        >
                          <p className="mb-0 ff_inter fs_24 text-white">
                            About Us
                          </p>
                          <img loading="lazy" src={arrow} alt="arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="slider-card p-3">
                      <div>
                        <p className=" ff_baijam fw-medium fs_32 text-white mb-0">
                          Our Mission
                        </p>
                      </div>
                      <p className="ff_inter fs_20 my-1 text-white opacity_90">
                        Empowering growth through innovation.{" "}
                      </p>
                      <p className="ff_inter fs_16 mb-0 text-white opacity_70 fw-light">
                        At Bamboo Ruts, we believe in strong foundations.
                        Inspired by Chinese bamboo, we nurture roots before
                        reaching new heights. Our tailored digital marketing
                        solutions empower businesses to thrive with a robust
                        foundation for success.
                      </p>
                      <div className="d-flex align-items-center gap-2 mt-3">
                        <Link
                          className="d-flex align-items-center gap-2"
                          to={"/about"}
                        >
                          <p className="mb-0 ff_inter fs_24 text-white">
                            About Us
                          </p>
                          <img loading="lazy" src={arrow} alt="arrow" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Slider>
                <div className="d-flex gap-2 justify-content-center mt-4 d-md-none">
                  <button
                    onClick={() => button.current.slickPrev()}
                    className="btn-next d-flex align-items-center justify-content-center"
                  >
                    <img loading="lazy" src={prevarrow} alt="arrow" />
                  </button>
                  <button
                    onClick={() => button.current.slickNext()}
                    className="btn-next d-flex align-items-center justify-content-center"
                  >
                    <img loading="lazy" src={nextarrow} alt="arrow" />
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhoWeAre;
