import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AboutUsHero from "../components/AboutUs/AboutUsHero/AboutUsHero";
import AboutUs from "../components/AboutUs/AboutUs/AboutUs";
import WhatWeDo from "../components/AboutUs/WhatWeDo/WhatWeDo";
import WhatAreClientSay from "../components/clientSays/WhatAreClientSay";
import AppFooter from "../components/footer/AppFooter";
import GetInTouch from "../components/getInTouch/GetInTouch";
import panda from "../assets/images/svg/pandaHanging.svg";

const AboutUS = () => {
  return (
    <>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_about={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            about_bold="font-nav"
          />
        </nav>
        <AboutUsHero />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 height-100v d-flex flex-column justify-content-center overflow-hidden pt-5">
          <AboutUs />
          <WhatWeDo />
        </div>
        <WhatAreClientSay />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </>
  );
};

export default AboutUS;
