import React from "react";
import "./Blogs.css";
import { Col, Row } from "react-bootstrap";
import person from "../../../assets/images/png/personWithLaptop.png";
import demand from "../../../assets/images/png/demand.webp";
import firstThing from "../../../assets/images/png/firstThing.jpg";
import adTech from "../../../assets/images/png/aiBasedCamp.jpeg";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="blog-1 overflow-hidden">
          <Row className="align-items-center flex-column-reverse flex-md-row">
            <Col md={6} className="p-5">
              <div>
                <h3 className="mb-0 text-white ff_petrona fs_32">
                  SEO for <span className="fw-bold">Business Growth</span>
                </h3>
                <p className="mb-0 opacity_70 text-white fs_16 ff_inter fw-light mt-2">
                  In the ever-evolving landscape of digital marketing,
                  understanding and harnessing the potential of SEO skills has
                  become paramount for the sustained growth of businesses......
                </p>
                <div className="mt-4 d-flex align-items-center justify-content-between">
                  <p className="mb-0 text-white fs_16 ff_inter fw-light">
                    May 20th
                  </p>
                  <Link to={"/seo-for-bussiness"}>
                    <p className="mb-0 text-white fs_16 ff_inter fw-bold">
                      Read more
                    </p>
                  </Link>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <img
                  loading="lazy"
                  className="w-100 object-fit-cover person-img"
                  src={person}
                  alt=""
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <Row className="justify-content-center">
            <Col md={6} lg={4}>
              <div className="blog-2-3-cards h-100">
                <div>
                  <img
                    loading="lazy"
                    style={{ borderRadius: "6px 6px 0 0" }}
                    className="w-100 blogs-img"
                    src={demand}
                    alt="laptop"
                  />
                </div>
                <div className="px-3 py-4">
                  <h3 className="mb-0 clr_dark_blue ff_petrona fs_24">
                    Negative Demand in{" "}
                    <span className="fw-bold">Marketing</span>
                  </h3>
                  <p className="mb-0 opacity_70 clr_dark_grey fs_16 ff_inter fw-light mt-2">
                    Negative demand in marketing refers to a situation where a
                    substantial part of the market dislikes a product or service
                    and may even pay to avoid it. This scenario is particularly
                    challenging for marketers, as they must find ....
                  </p>
                  <div className="mt-4 d-flex align-items-center justify-content-between">
                    <p className="mb-0 clr_dark_grey fs_16 ff_inter fw-light">
                      May 20th
                    </p>
                    <Link to={"/negative-marketing-demands"}>
                      <p className="mb-0 clr_dark_blue fs_16 ff_inter fw-bold">
                        Read more
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="mt-4 mt-md-0" md={6} lg={4}>
              <div className="blog-2-3-cards h-100 d-flex flex-column justify-content-between">
                <div>
                  <div>
                    <img
                      loading="lazy"
                      style={{ borderRadius: "6px 6px 0 0" }}
                      className="w-100 blogs-img"
                      src={firstThing}
                      alt="laptop"
                    />
                  </div>
                  <div className="px-3 pt-4 ">
                    <h3 className="mb-0 clr_dark_blue ff_petrona fs_24">
                      First Thing you{" "}
                      <span className="fw-bold">should know</span>
                    </h3>
                    <p className="mb-0 opacity_70 clr_dark_grey fs_16 ff_inter fw-light mt-2">
                      Here are the free personality development tips by our
                      expert trainer. If you are wondering how to develop your
                      personality, first thing you should start with is to love
                      yourself!....
                    </p>
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-center justify-content-between px-3 pb-4">
                  <p className="mb-0 clr_dark_grey fs_16 ff_inter fw-light">
                    May 10th
                  </p>
                  <Link to={"/first-thing-to-develop"}>
                    <p className="mb-0 clr_dark_blue fs_16 ff_inter fw-bold">
                      Read more
                    </p>
                  </Link>
                </div>
              </div>
            </Col>
            <Col className="mt-4 mt-md-0" md={6} lg={4}>
              <div className="blog-2-3-cards h-100 d-flex flex-column justify-content-between">
                <div>
                  <div>
                    <img
                      loading="lazy"
                      style={{ borderRadius: "6px 6px 0 0" }}
                      className="w-100 blogs-img"
                      src={adTech}
                      alt="robot"
                    />
                  </div>
                  <div className="px-3 pt-4 ">
                    <h3 className="mb-0 clr_dark_blue ff_petrona fs_24">
                      From Automation to AI: The Evolution of{" "}
                      <span className="fw-bold">Ad Tech</span>
                    </h3>
                    <p className="mb-0 opacity_70 clr_dark_grey fs_16 ff_inter fw-light mt-2">
                      The ad tech industry is constantly revolutionising the way
                      businesses and brands communicate with their customers
                      online. This has significantly impacted the growth of the
                      ad tech....
                    </p>
                  </div>
                </div>
                <div className="mt-4 d-flex align-items-center justify-content-between px-3 pb-4">
                  <p className="mb-0 clr_dark_grey fs_16 ff_inter fw-light">
                    March 18
                  </p>
                  <Link to={"/automation-to-ai"}>
                    <p className="mb-0 clr_dark_blue fs_16 ff_inter fw-bold">
                      Read more
                    </p>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
