import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import BlogHero from "../components/blog/BlogHero";
import GetInTouch from "../components/getInTouch/GetInTouch";
import AppFooter from "../components/footer/AppFooter";
import Blogs from "../components/blog/Blogs/Blogs";
import panda from "../assets/images/svg/pandaHanging.svg";

const BlogPage = () => {
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_blog={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            blog_bold="font-nav"
          />
        </nav>
        <BlogHero />
      </header>
      <main>
        <Blogs />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default BlogPage;
