import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import BlogDetailHero from "../components/blog/BlogDetail/BlogDetailHero";
import BlogDetails from "../components/blog/BlogDetail/BlogDetails";
import GetInTouch from "../components/getInTouch/GetInTouch";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";

const BlogDetailsPage = () => {
  return (
    <div>
      <AppNavbar />
      <BlogDetailHero />
      <BlogDetails />
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default BlogDetailsPage;
