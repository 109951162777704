import React from "react";
import "./ServiceDetails.css";
import { Container } from "react-bootstrap";

const ProvidedServiceDetails = ({ serviceContent }) => {
  return (
    <div className="py-5">
      <Container>
        <h2 className="ff_petrona text-white fs_40 mb-0 fw-semibold text-center mt-5 pt-3">
          Our Services
        </h2>
        <div className="my-5 pb-5">{serviceContent}</div>
      </Container>
    </div>
  );
};

export default ProvidedServiceDetails;
