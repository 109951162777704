import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import pandaSittinng from "../assets/images/png/performanceMarketing.png";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const PerformanceMarketingPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            1. Pay-Per-Click (PPC) Advertising
          </span>{" "}
          Harness the power of targeted advertising with our expert PPC
          services. We create and manage campaigns across Google Ads, Bing Ads,
          and social media platforms to ensure your ads reach the right audience
          at the right time
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Keyword Research & Strategy
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Ad Copy Creation & Testing
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Bid Management & Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking & Reporting
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">2. Social Media Advertising</span>{" "}
          Engage with your audience where they spend the most time. Our social
          media advertising services focus on building brand awareness, driving
          website traffic, and increasing sales through platforms like Facebook,
          Instagram, LinkedIn, and Twitter.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Audience Targeting & Segmentation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Ad Design & Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Campaign Management & Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Analytics & Insights
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            3. Search Engine Optimization (SEO)
          </span>{" "}
          Achieve higher rankings on search engines and attract more organic
          traffic with our comprehensive SEO services. We use the latest
          techniques and tools to improve your website's visibility and search
          engine performance
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            On-Page & Off-Page SEO
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Keyword Research & Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Link Building Strategies
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">
            4. Conversion Rate Optimization (CRO)
          </span>{" "}
          Maximize the effectiveness of your website and landing pages with our
          CRO services. We analyze user behavior and implement strategies to
          increase the percentage of visitors who convert into customers.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Website & Landing Page Audits
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            A/B Testing & Multivariate Testing
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            User Experience (UX) Enhancements
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Funnel Optimization
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">5. Affiliate Marketing</span> Expand
          your reach and drive sales through our affiliate marketing programs.
          We manage all aspects of your affiliate marketing efforts, from
          recruiting partners to tracking performance and optimizing campaigns.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Affiliate Program Setup
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Partner Recruitment & Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Monitoring
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Commission Structure Optimization
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">6. Email Marketing </span> Nurture leads
          and retain customers with targeted email marketing campaigns. Our
          services include everything from list management and segmentation to
          design, execution, and analysis.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Email List Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Campaign Design & Execution
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Personalization & Automation
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Performance Tracking & Reporting
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Drive Results with Precision"
          mainImg={pandaSittinng}
          mainPara=" At Bamboo Ruts Digital Marketing Agency, we specialize in performance marketing services designed to deliver measurable results and maximize your return on investment. Our data-driven approach ensures that every marketing dollar is spent wisely, driving traffic, generating leads, and boosting conversions."
          mainHeading="Performance Marketing Services at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden pt-5 pb-4">
          <ProvidedServiceDetails serviceContent={serviceContent} />
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Data-Driven Approach",
              para: `We base our strategies on in-depth data analysis to ensure optimal performance and continuous improvement.`,
            },
            {
              head: "Expert Team",
              para: `Our team of experienced brand strategists, designers, and storytellers work collaboratively to bring your brand to life.`,
            },
            {
              head: "Tailored Solutions",
              para: `We customize our services to meet your specific needs and goals, ensuring that your brand stands out in your industry.`,
            },
            {
              head: "Innovative Strategies",
              para: `We use the latest tools and techniques to develop innovative branding strategies that drive results.`,
            },
            {
              head: "Client Partnership",
              para: `We see ourselves as partners in your success, working closely with you to understand your vision and bring it to fruition.`,
            },
          ]}
          brandJourney="Ready to build a brand that captivates and converts? Contact Bamboo Ruts Digital Marketing Agency to learn more about our branding services and how we can help you create a brand that truly represents your business."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default PerformanceMarketingPage;
