import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import branding from "../assets/images/png/branding.webp";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const BrandingPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">1. Brand Strategy Development </span>{" "}
          Creating a powerful brand starts with a solid strategy. We work
          closely with you to define your brand’s mission, vision, values, and
          unique selling propositions.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Brand Positioning</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Market & Competitor Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Messaging & Voice
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Vision & Mission Statement
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">2. Brand Identity Design </span> Your
          brand identity is how your business looks, feels, and communicates
          with the world. We craft cohesive and visually appealing brand
          identities that leave a lasting impression.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Logo Design</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Color Palette & Typography
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Visual Style Guide</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Collateral Design (Business Cards, Letterheads, etc.)
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">3. Brand Storytelling</span>Every brand
          has a story to tell. We help you articulate and share your brand’s
          story in a way that connects emotionally with your audience.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Narrative Development
          </li>
          <li className="text-white ff_inter fs_16 mt-1"> Content Creation</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Storytelling Workshops
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Video Production
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Rebranding</span>Whether you’re
          looking to refresh your existing brand or completely overhaul your
          brand identity, our rebranding services ensure a smooth and effective
          transition.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Brand Audit</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Strategy Redefinition
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            New Identity Design
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Launch Strategy & Execution
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">5. Brand Guidelines </span> Consistency
          is key to maintaining a strong brand. We create detailed brand
          guidelines to ensure that every aspect of your brand is represented
          accurately across all platforms and materials.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Comprehensive Brand Manuals
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Logo Usage Rules</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Typography & Color Guidelines
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Tone of Voice & Messaging
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">6. Brand Experience </span> Your brand
          is more than just visuals and messaging—it’s the experience you
          deliver. We design every touchpoint to provide a seamless and
          memorable brand experience.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Customer Journey Mapping
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Touchpoint Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Experience Design</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Activation & Events
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Brand Monitoring & Management </span>{" "}
          We help you maintain the integrity of your brand with ongoing
          monitoring and management services, ensuring that your brand stays
          relevant and effective.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Performance Tracking
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Reputation Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1"> Brand Audits</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Improvement Strategies
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Elevate Your Brand Identity"
          mainImg={branding}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we understand that a strong brand is the cornerstone of a successful business. Our comprehensive branding services are designed to help you build a memorable and impactful brand that resonates with your target audience and stands out in the marketplace."
          mainHeading="Branding"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden pt-5 pb-4">
          <ProvidedServiceDetails serviceContent={serviceContent} />
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Holistic Approach",
              para: `We take a comprehensive approach to branding, considering every aspect of your brand to create a cohesive and powerful identity.`,
            },
            {
              head: "Expert Team",
              para: `Our team of experienced brand strategists, designers, and storytellers work collaboratively to bring your brand to life.`,
            },
            {
              head: "Tailored Solutions",
              para: `We customize our services to meet your specific needs and goals, ensuring that your brand stands out in your industry.`,
            },
            {
              head: "Innovative Strategies",
              para: `We use the latest tools and techniques to develop innovative branding strategies that drive results.`,
            },
            {
              head: "Client Partnership",
              para: `We see ourselves as partners in your success, working closely with you to understand your vision and bring it to fruition.`,
            },
          ]}
          brandJourney="Ready to build a brand that captivates and converts? Contact Bamboo Ruts Digital Marketing Agency to learn more about our branding services and how we can help you create a brand that truly represents your business."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default BrandingPage;
