import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h2 className="ff_inter fs_32 fw-bold clr_dark_blue mb-0 mt-4">
          Privacy Policy
        </h2>
        <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
          At Bamboo Ruts, accessible from{" "}
          <a href="#"> https:// Bamboo Ruts.com</a>, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by Bamboo Ruts and
          how we use it. If you have additional questions or require more
          information about our Privacy Policy, do not hesitate to contact us.
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regard to the information that they
          shared and/or collect in Bamboo Ruts. This policy is not applicable to
          any information collected offline or via channels other than this
          website.
        </p>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            Consent{" "}
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-1">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide. When you register for
            an Account, we may ask for your contact information, including items
            such as name, company name, address, email address, and telephone
            number.
          </p>
        </div>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            How we use your information{" "}
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-2">
            We use the information we collect in various ways, including:
          </p>
          <div>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Provide, operate, and maintain our website
            </p>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Improve, personalize, and expand our website
            </p>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Understand and analyze how you use our website
            </p>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Develop new products, services, features, and functionality
            </p>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </p>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Send you emails
            </p>
            <p className="ff_inter fs_18 fw-normal clr_dark_blue mb-0 mt-2">
              • Find and prevent fraud
            </p>
          </div>
        </div>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            Log Files{" "}
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
            Bamboo Ruts follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services’ analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users’ movement on the website, and gathering
            demographic information.
          </p>
        </div>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            Cookies and Web Beacons{" "}
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
            Like any other website, Bamboo Ruts uses ‘cookies’. These cookies
            are used to store information including visitors’ preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users’ experience by customizing
            our web page content based on visitors’ browser type and/or other
            information.
          </p>
        </div>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            Advertising Partners Privacy Policies{" "}
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Bamboo Ruts. <br /> Third-party ad servers
            or ad networks uses technologies like cookies, JavaScript, or Web
            Beacons that are used in their respective advertisements and links
            that appear on Bamboo Ruts, which are sent directly to users’
            browser. They automatically receive your IP address when this
            occurs. These technologies are used to measure the effectiveness of
            their advertising campaigns and/or to personalize the advertising
            content that you see on websites that you visit. Note that Bamboo
            Ruts has no access to or control over these cookies that are used by
            third-party advertisers.
          </p>
        </div>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            Third Party Privacy Policies{" "}
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
            Bamboo Ruts’s Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt out of certain options.
          </p>
          <p className="fw-normal ff_inter fs_14 clr_dark_blue mb-0 mt-4">
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found on the browsers’
            respective websites.
          </p>
        </div>
        <div>
          <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-4">
            GDPR Data Protection Rights{" "}
          </p>
          <div>
            <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-2">
              The right to access :{" "}
              <span className="fw-light">
                You have the right to request copies of your personal data. We
                may charge you a small fee for this service.
              </span>
            </p>
          </div>
          <div>
            <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-2">
              The right to rectification :{" "}
              <span className="fw-light">
                You have the right to request that we correct any information
                you believe is inaccurate. You also have the right to request
                that we complete the information you believe is incomplete.
              </span>
            </p>
          </div>
          <div>
            <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-2">
              The right to erasure :{" "}
              <span className="fw-light">
                You have the right to request that we erase your personal data,
                under certain conditions.
              </span>
            </p>
          </div>
          <div>
            <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-2">
              The right to restrict processing :{" "}
              <span className="fw-light">
                You have the right to request that we restrict the processing of
                your personal data, under certain conditions.
              </span>
            </p>
          </div>
          <div>
            <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-2">
              The right to object to processing :{" "}
              <span className="fw-light">
                You have the right to object to our processing of your personal
                data, under certain conditions.
              </span>
            </p>
          </div>
          <div>
            <p className="ff_inter fs_18 fw-semibold clr_dark_blue mb-0 mt-2">
              The right to data portability :{" "}
              <span className="fw-light">
                You have the right to request that we transfer the data that we
                have collected to another organization, or directly to you,
                under certain conditions. If you make a request, we have one
                month to respond to you. If you would like to exercise any of
                these rights, please contact us.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
