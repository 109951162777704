import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import Hero from "../components/hero/Hero";
import WhoWeAre from "../components/WhoWeAre/WhoWeAre";
import ServiceExamples from "../components/ServiceExample/ServiceExamples";
import WhatAreClientSay from "../components/clientSays/WhatAreClientSay";
import GetInTouch from "../components/getInTouch/GetInTouch";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";

export const Home = () => {
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_home={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            home_bold="font-nav"
          />
        </nav>
        <Hero />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 height-100v d-flex flex-column justify-content-center overflow-hidden pt-5">
          <WhoWeAre />
          <ServiceExamples />
        </div>
        <WhatAreClientSay />
      </main>
      <div className="get-in-touch-footer-bg  position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};
