import React from "react";
import AppNavbar from "../components/Navbar/AppNavbar";
import AppFooter from "../components/footer/AppFooter";
import panda from "../assets/images/svg/pandaHanging.svg";
import ServiceDetailHero from "../components/ServiceDetails/ServiceDetailHero";
import marketing from "../assets/images/png/marketing.webp";
import GetInTouch from "../components/getInTouch/GetInTouch";
import ProvidedServiceDetails from "../components/ServiceDetails/ProvidedServiceDetails";
import WhyChooseUs from "../components/ServiceDetails/WhyChooseUs";

const OnlineReputationPage = () => {
  const serviceContent = (
    <div>
      <div style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">1. Reputation Assessment </span>We begin
          by conducting a thorough assessment of your current online reputation.
          This involves analyzing your presence across various platforms to
          identify strengths, weaknesses, and areas for improvement.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Comprehensive Online Audit
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Sentiment Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Perception Analysis
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Competitor Benchmarking
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">2. Strategy Development </span> Based on
          the assessment, we develop a customized ORM strategy tailored to your
          specific needs and objectives. This strategy outlines the steps
          required to build and maintain a positive online presence.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Customized ORM Plan
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Goal Setting & KPI Definition
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Audience Targeting</li>
          <li className="text-white ff_inter fs_16 mt-1">Content Strategy</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">3. Content Strategy </span>
          Continuous monitoring of your online presence allows us to track brand
          mentions, reviews, and sentiment across the internet. We use advanced
          tools to stay informed about what is being said about your brand in
          real-time.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Real-Time Monitoring
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Brand Mentions Tracking
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Sentiment Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">Customized Alerts</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">4. Customized Alerts</span>
          Online reviews significantly impact your reputation. We help you
          manage reviews across various platforms, responding promptly to
          positive and negative feedback to show your commitment to customer
          satisfaction.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">Review Monitoring</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Response Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            {" "}
            Encouraging Positive Reviews
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Mitigating Negative Reviews
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">5. Content Creation & Promotion </span>
          Creating and promoting positive content about your brand helps to
          enhance your reputation. We develop engaging content that showcases
          your brand’s strengths and values.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Blog Posts & Articles
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Press Releases</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Social Media Content
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Video Production</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">6. Crisis Management </span> In the
          event of a reputational crisis, we provide swift and effective crisis
          management services to minimize damage and restore your brand’s image.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Crisis Response Planning
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Communication Strategy
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Media Outreach</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Damage Control Measures
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">7. Damage Control Measures </span> We
          use advanced SEO techniques to promote positive content and suppress
          negative content in search engine results, ensuring that favorable
          information about your brand is more visible.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Positive Content Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Negative Content Suppression
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Search Engine Visibility Improvement
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Keyword Optimization
          </li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">8. Social Media Management </span> Your
          social media presence plays a significant role in your online
          reputation. We manage your social media profiles to ensure consistent
          and positive engagement with your audience.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Profile Optimization
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Content Planning & Posting
          </li>
          <li className="text-white ff_inter fs_16 mt-1">
            Community Management
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Social Listening</li>
        </ul>
      </div>
      <div className="mt-5" style={{ maxWidth: "1000px" }}>
        <p className="ff_inter fs_16 text-white">
          <span className="fw-semibold">9. Social Listening </span> We provide
          regular reports to keep you informed about your online reputation.
          These reports include insights and recommendations for ongoing
          reputation management.
        </p>
        <ul className="key-points-ul ps-5">
          <li className="text-white ff_inter fs_16 mt-1">
            Monthly Performance Reports
          </li>
          <li className="text-white ff_inter fs_16 mt-1">Sentiment Analysis</li>
          <li className="text-white ff_inter fs_16 mt-1">Review Summary</li>
          <li className="text-white ff_inter fs_16 mt-1">
            Continuous Improvement Suggestions
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div>
      <header className="height-100vh d-flex flex-column position-relative overflow-hidden">
        <nav>
          <AppNavbar
            display_services={
              <img
                loading="lazy"
                className={`panda-hanging position-absolute`}
                src={panda}
                alt=""
              />
            }
            service_bold="font-nav"
          />
        </nav>
        <ServiceDetailHero
          subHeading="Protect and Enhance Your Brand’s Online Image"
          mainImg={marketing}
          mainPara="At Bamboo Ruts Digital Marketing Agency, we understand that your online reputation is crucial to your business’s success. Our comprehensive online reputation management (ORM) services are designed to protect, manage, and enhance your brand’s online image, ensuring that you are perceived positively by your audience."
          mainHeading="Online Reputation Management Services at Bamboo Ruts Digital Marketing Agency"
        />
      </header>
      <main>
        <div className="who-we-are-bg position-relative z-2 d-flex flex-column justify-content-center overflow-hidden py-5">
          <div className="py-5 mt-5">
            <ProvidedServiceDetails serviceContent={serviceContent} />
          </div>
        </div>
        <WhyChooseUs
          mapData={[
            {
              head: "Expertise & Experience",
              para: `: Our team of ORM specialists has extensive experience in managing and enhancing online reputations across various industries.`,
            },
            {
              head: "Customized Solutions",
              para: `We tailor our ORM services to meet the unique needs and goals of your business.`,
            },
            {
              head: "Proactive Approach",
              para: `We take a proactive approach to managing your online reputation, ensuring that potential issues are addressed before they escalate.`,
            },
            {
              head: "Transparent Reporting",
              para: `We provide clear and detailed reports, giving you full visibility into your reputation management efforts.`,
            },
            {
              head: "Client-Centric Focus",
              para: `We work closely with you to understand your needs and ensure our strategies align with your objectives.`,
            },
          ]}
          journeyHead="Protect Your Reputation Today"
          brandJourney="Ready to safeguard and enhance your brand’s online image? Contact Bamboo Ruts Digital Marketing Agency to learn more about our online reputation management services and how we can help you maintain a positive and influential online presence."
        />
      </main>
      <div className="get-in-touch-footer-bg position-relative z-2">
        <div className="footer-overlay position-absolute"></div>
        <GetInTouch />
        <AppFooter />
      </div>
    </div>
  );
};

export default OnlineReputationPage;
